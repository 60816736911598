@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box
    }

    /* scroll bar customization */
    /* width */
    ::-webkit-scrollbar {
        width: 10px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #212121;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #757575;
        border-radius: 5px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #9E9E9E;
    }
}

@layer components {
    .btn {
        @apply bg-blue-500 text-white font-bold py-2 px-4 rounded;
    }

    .index-home {
        @apply w-full h-screen flex items-center justify-normal flex-col bg-no-repeat overflow-hidden;
        background-position: center center !important;
        background-size: cover !important;
        background-repeat: no-repeat !important;
        background:
            linear-gradient(180deg, #00000099, #00000088, #00000077, #00000055, #00000044, #00000033, #00000022, #000000),
            url('/public/assets/background.png');
    }

    .index-contact {
        @apply w-full h-screen flex items-center justify-normal flex-col bg-no-repeat overflow-hidden;
        background-position: center center !important;
        background-size: cover !important;
        background-repeat: no-repeat !important;
        background:
            linear-gradient(180deg, #00000099, #00000088, #00000077, #00000055, #00000044, #00000033, #00000022, #000000),
            url('/public/assets/background_about.png');
    }

    .index-resume {
        @apply w-full h-screen flex items-center justify-normal flex-col bg-no-repeat overflow-hidden;
        background-position: center center !important;
        background-size: cover !important;
        background-repeat: no-repeat !important;
        background:
            linear-gradient(180deg, #00000099, #00000088, #00000077, #00000055, #00000044, #00000033, #00000022, #000000),
            url('/public/assets/background_resume.png');
    }

    .index-works {
        @apply w-full h-screen flex items-center justify-normal flex-col bg-no-repeat overflow-hidden;
        background-position: center center !important;
        background-size: cover !important;
        background-repeat: no-repeat !important;
        background:
            linear-gradient(180deg, #00000099, #00000088, #00000077, #00000055, #00000044, #00000033, #00000022, #000000),
            url('/public/assets/background_works.png');
    }

    .index-404 {
        @apply w-full h-screen flex items-center justify-normal flex-col bg-no-repeat overflow-hidden;
        background-position: center center !important;
        background-size: cover !important;
        background-repeat: no-repeat !important;
        background:
            linear-gradient(180deg, #00000099, #00000088, #00000077, #00000055, #00000044, #00000033, #00000022, #000000),
            url('/public/assets/background_404.png');
    }

    .radial-background-red {
        background: radial-gradient(circle, #F44336 0%, transparent 50%);
    }

    .radial-background-green {
        background: radial-gradient(circle, #4CAF50 0%, transparent 50%);
    }

    .text-shadow-white {
        text-shadow: 5px 5px 20px #fff;
    }

    .title {
        @apply text-lg md:text-xl lg:text-2xl font-bold text-white text-shadow-white transition duration-100 hover:text-yellow-300 hover:cursor-pointer
    }

    .title-withouthover {
        @apply text-lg md:text-xl lg:text-2xl font-bold text-white text-shadow-white
    }

    .subtitle {
        @apply text-xs md:text-sm lg:text-lg font-bold text-white text-shadow-white transition duration-100 hover:text-yellow-300 hover:cursor-pointer
    }

    .subtitle-withouthover {
        @apply text-xs md:text-sm lg:text-lg font-bold text-white text-shadow-white
    }

    .btnHover {
        @apply px-5 py-4 bg-white text-gray-800 text-2xl font-bold transition duration-100 hover:text-white hover:bg-yellow-500 rounded-2xl shadow-xl cursor-pointer
    }

    .btnHoverResume {
        @apply p-2 sm:p-3 bg-zinc-800 text-white text-lg sm:text-xl font-bold transition duration-100 border md:border-2 border-solid border-green-500 hover:text-white hover:bg-green-500 rounded-lg shadow-md md:shadow-2xl shadow-white cursor-pointer
    }

    .btnHoverWorks {
        @apply p-2 sm:p-3 md:p-4 bg-yellow-500 text-white text-sm sm:text-xl md:text-2xl font-bold transition duration-100 hover:text-yellow-500 hover:bg-white rounded-xl md:rounded-2xl shadow-xl cursor-pointer;
        box-shadow: 1px 1px 5px white;
    }

    .btnCarouse {
        @apply p-2 sm:p-3 bg-yellow-500 text-white text-xs sm:text-xl font-bold transition duration-100 hover:text-yellow-400 hover:bg-zinc-700 rounded-xl sm:rounded-2xl shadow-xl cursor-pointer;
        box-shadow: 2px 2px 5px dark;
    }

    .custom-animation {
        animation: translateAndBack 0.5s ease-in-out infinite alternate;
    }

    .custom-animation-rotate {
        animation: translateRotate360 1s ease-in-out 0.5s 1;
    }

    .zIndex10 {
        z-index: 10 !important;
    }

    .zIndex20 {
        z-index: 20 !important;
    }

    .zIndex30 {
        z-index: 30 !important;
    }
    
    .zIndex40 {
        z-index: 40 !important;
    }

    @keyframes translateAndBack {
        0% {
            transform: translateY(0);
        }

        50% {
            transform: translateY(-10px);
        }

        100% {
            transform: translateY(0);
        }
    }

    @keyframes translateRotate360 {
        0% {
            transform: rotate(0deg);
        }

        50% {
            transform: rotate(180deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }

    .blink-animation {
        animation: blink 2s ease 0.5s infinite alternate;
    }

    @keyframes blink {
        0% {
            opacity: 0;
        }

        20% {
            opacity: 10;
        }

        50% {
            opacity: 20;
        }

        100% {
            opacity: 30;
        }
    }

    .customHover {
        @apply transition-all duration-100 hover:cursor-pointer shadow-slate-900 shadow-2xl rounded-xl overflow-hidden;
    }

    .neumorphism {
        @apply bg-[#f0f0f0] text-black border-none py-4 px-6 text-lg cursor-pointer rounded-xl;
        box-shadow: 5px 5px 15px #c7c7c7, -5px -5px 15px #ffffff;
        transition: box-shadow 0.3s ease;

        &:hover {
            box-shadow: 8px 8px 20px #c7c7c7, -8px -8px 20px #ffffff;
        }

        &:active {
            box-shadow: inset 5px 5px 15px #c7c7c7, inset -5px -5px 15px #ffffff;
        }
    }

    .progressBar-100 {
        @apply relative flex items-center justify-center w-24 h-24 md:w-28 md:h-28 rounded-full;
        background: conic-gradient(#4CAF50 0% 100%, transparent 0% 100%);
    }

    .progressBar-90 {
        @apply relative flex items-center justify-center w-24 h-24 md:w-28 md:h-28 rounded-full;
        background: conic-gradient(#4CAF50 0% 90%, transparent 0% 100%);
    }

    .progressBar-80 {
        @apply relative flex items-center justify-center w-24 h-24 md:w-28 md:h-28 rounded-full;
        background: conic-gradient(#4CAF50 0% 80%, transparent 0% 100%);
    }

    .progressBar-70 {
        @apply relative flex items-center justify-center w-24 h-24 md:w-28 md:h-28 rounded-full;
        background: conic-gradient(#4CAF50 0% 70%, transparent 0% 100%);
    }

    .progressBar-60 {
        @apply relative flex items-center justify-center w-24 h-24 md:w-28 md:h-28 rounded-full;
        background: conic-gradient(#4CAF50 0% 60%, transparent 0% 100%);
    }

    .progressBar-50 {
        @apply relative flex items-center justify-center w-24 h-24 md:w-28 md:h-28 rounded-full;
        background: conic-gradient(#4CAF50 0% 50%, transparent 0% 100%);
    }

    .progressBar2 {
        @apply absolute flex items-center justify-center w-20 h-20 md:w-26 md:h-26 rounded-full bg-zinc-900;
    }

    .dropShadowRed {
        filter: drop-shadow(4px 4px 10px #F44336)
    }

    .dropShadowWhite {
        filter: drop-shadow(4px 4px 10px #fff)
    }

    .dropShadowBlue {
        filter: drop-shadow(4px 4px 10px #2196F3)
    }

    .dropShadowGreen {
        filter: drop-shadow(4px 4px 10px #4CAF50)
    }

    .dropShadowPurple {
        filter: drop-shadow(4px 4px 10px #673AB7)
    }
}